import { useDispatch } from "react-redux";
import { ModalBase } from "../ModalBase";
import { closeCurrentModal } from "store/modal/slice";
import { useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import HubSpotForm from "components/forms/HubSpotForm";

export const ContactSalesModal: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [showClose, setShowClose] = useState<boolean>(false);

    return (
        <div className={styles["contact-sales-modal"]}>
            <ModalBase
                header={t("buttons:contact-sales")}
                dismissButton={showClose ? t("buttons:close") : undefined}
                onDismiss={() => dispatch(closeCurrentModal())}
                isOpen
            >
                <HubSpotForm
                    formId="1a1061e9-e2a0-48bd-a73f-92395b24a6a6"
                    onFormSubmitted={() => setShowClose(true)}
                    onFormReady={(form) => {
                        form.querySelector('select[name="department"]').value =
                            "Marketing";
                    }}
                />
            </ModalBase>
        </div>
    );
};
