import { Fragment, useCallback, useMemo, useState } from "react";
import {
    CreatorProduct,
    CreatorProductEntitlement,
    CreatorProductEntitlementsBindingModelDiscriminator
} from "@switcherstudio/switcher-api-client";
import { Button } from "components/buttons/Button";
import { PricingSelectModal } from "components/modal/PricingSelectModal";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useCreatorProductEntitlement } from "hooks/useCreatorProductEntitlement";
import { useGetCreatorProducts } from "hooks/useGetCreatorProducts";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import {
    GatedContentStatus,
    useStripeAccountInfo
} from "hooks/useStripeAccountInfo";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { exists } from "helpers/booleans";
import { AttentionModal } from "components/modal/AttentionModal";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "store/store";
import { useDispatch } from "react-redux";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import PassEmptyState from "components/empty-state/PassEmptyState";
import { AttentionModalTable } from "components/modal/AttentionModal/AttentionModalTable";
import { GatedContentPageTabs } from "store/view/types";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";

export interface CollectionGatedContentPageStripeSectionProps {
    videoPlayerId: string;
    onReset?: () => void;
}

export const CollectionGatedContentPageStripeSection = ({
    videoPlayerId,
    onReset
}: CollectionGatedContentPageStripeSectionProps) => {
    const {
        recurringProducts,
        products,
        loading: productsLoading
    } = useGetCreatorProducts();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const hasGatedContentAccess = useClaimCheck("gatedcontent");
    const hasRecurringPassesClaim = useClaimCheck(
        "gatedcontent.recurringpayments"
    );

    const [pricingSelectModalOpen, setPricingSelectModalOpen] =
        useState<boolean>(false);
    const [pricingModalDiscriminator, setPricingModalDiscriminator] = useState<
        CreatorProductEntitlementsBindingModelDiscriminator | undefined
    >();
    const [attentionModalOpen, setAttentionModalOpen] =
        useState<boolean>(false);
    const [existingEntitlement, setExistingEntitlement] =
        useState<CreatorProductEntitlement>();
    const [selectedProduct, setSelectedProduct] = useState<CreatorProduct>();
    const [selectedPlaylistBroadcastId, setSelectedPlaylistBroadcastId] =
        useState<string>();
    const {
        account,
        gatedContentStatus,
        loading: stripeLoading
    } = useStripeAccountInfo({
        expandDetails: true
    });
    const stripeConnected = gatedContentStatus === GatedContentStatus.READY;

    const resetData = useCallback(() => {
        setExistingEntitlement(null);
        setSelectedPlaylistBroadcastId(null);
        setPricingModalDiscriminator(null);
        setAttentionModalOpen(false);
        setPricingSelectModalOpen(false);
        onReset?.();
    }, [onReset]);

    const {
        loading: creatorProductEntitlementloading,
        videoPlayer,
        getVideoPlayer,
        addCreatorProductEntitlement,
        deleteCreatorProductEntitlement
    } = useCreatorProductEntitlement(videoPlayerId, {
        onAdd: resetData,
        onDelete: resetData
    });

    const videoPlayerEntitlement = useMemo<CreatorProductEntitlement>(
        () =>
            videoPlayer?.VideoPlayerEntitlements.ProductEntitlements.find(
                (e) =>
                    e.Product.IsActive &&
                    e.Product.Prices.some((p) => p.IsRecurring)
            ),
        [videoPlayer]
    );

    const openPricingModal = useCallback(
        (
            discriminator: CreatorProductEntitlementsBindingModelDiscriminator
        ) => {
            setPricingModalDiscriminator(discriminator);
            setPricingSelectModalOpen(true);
        },
        []
    );

    const { dispatchApiRequest: getSubscriberCount } = useSwitcherClient(
        (client) => client.creatorProducts_GetSubscriberCount
    );

    const { dispatchApiRequest: getEntitlements } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_GetByProductId
    );

    const handleAssignment = useCallback(
        async (creatorProduct: CreatorProduct) => {
            const productEntitlements =
                (await getEntitlements([existingEntitlement?.Product?.Id]))
                    ?.ProductEntitlements ?? [];
            const productActiveSubscriptions = await getSubscriberCount([
                account?.Id,
                existingEntitlement?.Product?.Id
            ]);
            const isOnlyEntitlement =
                productEntitlements.length === 1 &&
                existingEntitlement?.Id === productEntitlements[0]?.Id &&
                productActiveSubscriptions > 0;

            if (creatorProduct) {
                // create new product entitlement
                switch (pricingModalDiscriminator) {
                    case CreatorProductEntitlementsBindingModelDiscriminator._0:
                        if (isOnlyEntitlement) {
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: t(
                                        "gated-content-modal:errors:pass-with-subscribers-error"
                                    )
                                })
                            );
                            return;
                        }
                        await addCreatorProductEntitlement([
                            {
                                ProductEntitlements: [
                                    {
                                        ProductId: creatorProduct.Id,
                                        VideoPlayerId: videoPlayerId,
                                        Discriminator:
                                            CreatorProductEntitlementsBindingModelDiscriminator._0
                                    }
                                ]
                            }
                        ]);
                        break;
                    case CreatorProductEntitlementsBindingModelDiscriminator._1:
                        break;
                    case CreatorProductEntitlementsBindingModelDiscriminator._2:
                        await addCreatorProductEntitlement([
                            {
                                ProductEntitlements: [
                                    {
                                        ProductId: creatorProduct.Id,
                                        VideoPlayerPlaylistBroadcastId:
                                            selectedPlaylistBroadcastId,
                                        Discriminator:
                                            CreatorProductEntitlementsBindingModelDiscriminator._2
                                    }
                                ]
                            }
                        ]);
                }
            } else if (!!existingEntitlement) {
                if (isOnlyEntitlement) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                "gated-content-modal:errors:pass-with-subscribers-error"
                            )
                        })
                    );
                    return;
                }
                await deleteCreatorProductEntitlement([
                    existingEntitlement?.Id
                ]);
            }

            resetData();
            getVideoPlayer();
        },
        [
            t,
            dispatch,
            resetData,
            getVideoPlayer,
            getEntitlements,
            getSubscriberCount,
            addCreatorProductEntitlement,
            deleteCreatorProductEntitlement,
            existingEntitlement,
            pricingModalDiscriminator,
            selectedPlaylistBroadcastId,
            videoPlayerId,
            account?.Id
        ]
    );

    const filteredProducts = useMemo<CreatorProduct[]>(
        () => recurringProducts?.filter((p) => p.Name !== "Catalog Pass") ?? [],
        [recurringProducts]
    );

    const { link: stripeConnectLink } = useGetStripeConnectLink();

    if (!hasGatedContentAccess) return <StudioUpgradeCta />;

    return (
        <div className={styles["page-content"]}>
            {!stripeLoading && (
                <>
                    {!stripeConnected ? (
                        <PassEmptyState
                            title={t(
                                "players:gated-content:connect-stripe-account-title"
                            )}
                            message={t(
                                "players:gated-content:connect-stripe-account-description"
                            )}
                            linkText={t(
                                "players:gated-content:connect-stripe-account-link-text"
                            )}
                            linkHref={stripeConnectLink}
                        ></PassEmptyState>
                    ) : (
                        !productsLoading &&
                        !creatorProductEntitlementloading && (
                            <>
                                {hasRecurringPassesClaim && (
                                    <>
                                        {filteredProducts?.length > 0 ? (
                                            <div
                                                className={`${styles["pass-container"]} ${styles["recurring-pass"]}`}
                                            >
                                                <Button
                                                    type="badge"
                                                    isActive={exists(
                                                        videoPlayerEntitlement
                                                    )}
                                                    onClick={() => {
                                                        setExistingEntitlement(
                                                            videoPlayerEntitlement
                                                        );
                                                        openPricingModal(
                                                            CreatorProductEntitlementsBindingModelDiscriminator._0
                                                        );
                                                    }}
                                                >
                                                    {videoPlayerEntitlement
                                                        ?.Product.Name ??
                                                        t(
                                                            "video-player-settings:add-pricing"
                                                        )}
                                                </Button>
                                            </div>
                                        ) : (
                                            <PassEmptyState
                                                title={t(
                                                    "players:gated-content:recurring-pass"
                                                )}
                                                message={t(
                                                    "players:gated-content:add-subscription-pass"
                                                )}
                                                linkText={t(
                                                    "players:gated-content:add-subscription-pass-link-text"
                                                )}
                                                linkHref={
                                                    "/gated-content#passes"
                                                }
                                            ></PassEmptyState>
                                        )}
                                    </>
                                )}
                            </>
                        )
                    )}

                    {/* Unconditionally Rendered Modals */}

                    <PricingSelectModal
                        isOpen={pricingSelectModalOpen}
                        setIsOpen={setPricingSelectModalOpen}
                        handleSelect={async (selection) => {
                            // do nothing if it's the same as what was selected before
                            if (
                                selection?.Id ===
                                existingEntitlement?.Product?.Id
                            ) {
                                resetData();
                                return;
                            }
                            if (
                                !!selection &&
                                !!existingEntitlement &&
                                pricingModalDiscriminator ===
                                    CreatorProductEntitlementsBindingModelDiscriminator._0
                            ) {
                                setSelectedProduct(selection);
                                setAttentionModalOpen(true);
                            } else {
                                handleAssignment(selection);
                            }
                        }}
                        products={products}
                        selected={existingEntitlement?.ProductId}
                        discriminator={pricingModalDiscriminator}
                    />

                    <AttentionModal
                        isOpen={attentionModalOpen}
                        setIsOpen={setAttentionModalOpen}
                        handleContinue={() => {
                            setPricingSelectModalOpen(false);
                            handleAssignment(selectedProduct);
                        }}
                        handleCancel={() => {
                            setPricingSelectModalOpen(true);
                            setAttentionModalOpen(false);
                        }}
                        updateModal={() =>
                            navigate(
                                `/gated-content#${GatedContentPageTabs.Pricing}`,
                                {
                                    state: {
                                        searchParams: {
                                            pass: videoPlayerEntitlement.ProductId
                                        }
                                    }
                                }
                            )
                        }
                    >
                        <>
                            <div>
                                <p
                                    className={`${styles["undo-mb"]} ${styles["padding-bottom"]}`}
                                >
                                    {t("gated-content-page:attention-body")}
                                </p>
                            </div>
                            <AttentionModalTable
                                players={[videoPlayer]}
                                updateModal={() =>
                                    navigate(
                                        `/gated-content#${GatedContentPageTabs.Pricing}`,
                                        {
                                            state: {
                                                searchParams: {
                                                    pass: videoPlayerEntitlement.ProductId
                                                }
                                            }
                                        }
                                    )
                                }
                            />

                            <div className={`${styles["undo-mb"]}`}>
                                <p
                                    className={`${styles["undo-mb"]} ${styles["padding-top"]}`}
                                >
                                    {t("gated-content-page:attention-action")}
                                </p>
                            </div>
                        </>
                    </AttentionModal>
                </>
            )}
        </div>
    );
};
