import React from "react";
import { useTranslation } from "react-i18next";
import { VideoDetailsPage } from ".";
import { usePageHeader } from "hooks/usePageHeader";
import { useParams } from "hooks/useParams";
import { useSwitcherClient } from "hooks/useSwitcherClient";

/** this page is the sister page of CollectionVideoDetailsPage */
export const VideoLibraryVideoDetailsPage: React.FC = () => {
    const { t } = useTranslation();
    const { broadcastId } = useParams();

    const {
        data: broadcast,
        dispatchApiRequest: refetchBroadcast,
        loading
    } = useSwitcherClient(
        (client) => client.cloudRecordings_GetVideosForUserV2,
        {
            args: [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [broadcastId]
            ],
            requestImmediately: true
        }
    );

    usePageHeader({
        title: t("page-titles:video-details"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("page-titles:video-library"),
            t("page-titles:video-details")
        ]
    });

    return (
        <VideoDetailsPage
            broadcast={broadcast?.[0]?.broadcast}
            video={broadcast?.[0]?.videos?.[0]}
            players={broadcast?.[0]?.players}
            refetchBroadcast={refetchBroadcast}
            location="video-library"
            broadcastLoading={loading}
        />
    );
};
