import { TextInput } from "components/inputs/text-input/TextInput";
import { Toggle } from "components/inputs/toggle/Toggle";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

interface PasswordGatingToggleProps {
    isEnabled: boolean;
    setIsEnabled: (isEnabled: boolean) => void;
    password: string;
    setPassword: (password: string) => void;
    hasEmailOrPurchaseGating?: boolean;
    hasValidationError?: boolean;
}

export const PasswordGatingToggle = ({
    isEnabled,
    setIsEnabled,
    password = null,
    setPassword,
    hasEmailOrPurchaseGating = false,
    hasValidationError = false
}: PasswordGatingToggleProps) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles["password-section"]}`}>
            <Toggle
                on={isEnabled}
                onToggle={() => setIsEnabled(!isEnabled)}
                label={t("subscription-page:password-access")}
                reverseLayout
                disabled={false}
            />
            {isEnabled && (
                <TextInput
                    id={"password"}
                    label={t("subscription-page:password")}
                    type={"text"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    help={
                        hasEmailOrPurchaseGating
                            ? t("subscription-page:password-help")
                            : null
                    }
                    error={
                        hasValidationError
                            ? t("subscription-page:password-error")
                            : null
                    }
                    hideClear={true}
                    autocomplete="off"
                    maxLength={256}
                />
            )}
        </div>
    );
};
