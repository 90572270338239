import { useScript } from "hooks/useScript";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import rollbar from "helpers/rollbar";

interface HubSpotFormProps {
    formId: string;
    onFormSubmitted?: () => void;
    onFormReady?: (form: any) => void;
}

const HubSpotForm: React.FC<HubSpotFormProps> = ({
    formId,
    onFormSubmitted,
    onFormReady
}) => {
    const { i18n } = useTranslation();
    const status = useScript("//js.hsforms.net/forms/embed/v2.js");

    useEffect(() => {
        if (status === "ready") {
            try {
                hbspt.forms.create({
                    region: "na1",
                    portalId: "2974196",
                    formId: formId,
                    target: `#hubspot-form`,
                    locale: i18n.resolvedLanguage,
                    onFormSubmitted: onFormSubmitted,
                    onFormReady: onFormReady
                });
            } catch (e) {
                rollbar.error("HubSpot form failed to load", e, {
                    formId
                });
                onFormSubmitted && onFormSubmitted();
            }
        }
        if (status === "error") {
            rollbar.error("HubSpot script failed to load", null, {
                formId
            });
            onFormSubmitted && onFormSubmitted();
        }
    }, [i18n, status, formId, onFormSubmitted, onFormReady]);

    return (
        <div
            id={"hubspot-form"}
            className={`hubspot-form ${styles["hubspot-form"]}`}
        ></div>
    );
};

export default HubSpotForm;
