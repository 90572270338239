import { TriggeredVideoUploadModal } from "components/video-upload/TriggeredVideoUploadModal";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Modals } from "store/modal/types";
import { RootState } from "store/reducers";
import { LaunchCancelModal } from "./LaunchCancelModal";

export const ModalManager = () => {
    const { activeModal } = useSelector((s: RootState) => s.modal);

    const currentModal = useMemo(() => {
        if (activeModal?.component) {
            return activeModal?.component;
        }

        switch (activeModal?.type) {
            case Modals.LaunchCancelModal:
                return <LaunchCancelModal />;
            case Modals.TriggeredVideoUploadModal:
                return (
                    <TriggeredVideoUploadModal
                        externalUploadSessionId={activeModal.id}
                    />
                );
            case Modals.None:
            default:
                return <></>;
        }
    }, [activeModal]);

    return currentModal;
};
