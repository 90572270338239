import { client } from "api/client";
import { useBillingRedirect } from "./useBillingRedirect";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { parseBool } from "helpers/booleans";
import rollbar from "helpers/rollbar";
import { useTranslation } from "react-i18next";
import { useCheckout } from "./useCheckout";
import { useParams } from "react-router-dom";

export interface useCheckoutRedirectOptions {
    onSuccess?: (args?: { message?: string }) => void;
    onFailure?: (args?: { message?: string }) => void;
}

export const useCheckoutRedirect = ({
    onSuccess,
    onFailure
}: useCheckoutRedirectOptions) => {
    const { t } = useTranslation();
    const { userInfo } = useSelector((s: RootState) => s.user);

    const {
        currentSubscriptionId,
        resellerInventoryItemId,
        isNewSubscription,
        planId,
        isNewPaymentMethod,
        isTrailing,
        invoiceId
    } = useParams();

    const { createOrUpdateSubscription, createEntitlement } = useCheckout({
        licensedSubscription: currentSubscriptionId,
        plan: planId,
        isNewSubscription: parseBool(isNewSubscription),
        isTrialing: parseBool(isTrailing),
        resellerInventoryItemId
    });

    useBillingRedirect({
        onSetupIntent: async ({ id, status, payment_method }) => {
            switch (status) {
                case "succeeded":
                    const paymentMethodId =
                        typeof payment_method === "string"
                            ? payment_method
                            : payment_method.id;

                    if (isNewPaymentMethod) {
                        await client.userPaymentMethods_AttachPaymentMethod(
                            userInfo.UserId,
                            paymentMethodId
                        );
                    }

                    // Sets the selected payment method as primary after payment
                    await client.userPaymentMethods_SetPrimaryPaymentMethod(
                        userInfo.UserId,
                        paymentMethodId
                    );

                    if (planId) {
                        await createOrUpdateSubscription({
                            selectedPaymentMethodId: paymentMethodId,
                            planId
                        });
                    }

                    onSuccess?.({
                        message:
                            planId !== undefined && invoiceId !== undefined
                                ? t("subscription:success")
                                : t("messages:payment-add-success")
                    });
                    break;
                case "requires_payment_method":
                    onFailure?.();
                    break;
                case "processing":
                    rollbar.info(`Payment Intent is processing: ${id}`);
                    break;
                default:
                    // Handle any unexpected status
                    rollbar.warning(`Unexpected payment status: ${status}`, {
                        id
                    });
                    break;
            }
        },
        onPaymentIntent: async ({ id, status, payment_method }) => {
            switch (status) {
                case "succeeded":
                    const paymentMethodId =
                        typeof payment_method === "string"
                            ? payment_method
                            : payment_method.id;

                    await client.userPaymentMethods_SetPrimaryPaymentMethod(
                        userInfo.UserId,
                        paymentMethodId
                    );

                    if (invoiceId) {
                        await createEntitlement({
                            invoice: invoiceId,
                            selectedPaymentMethodId: paymentMethodId
                        });
                    }

                    onSuccess?.({
                        message: t("subscription:success")
                    });
                    break;
                case "requires_payment_method":
                    onFailure?.();
                    break;
                case "processing":
                    rollbar.info(`Payment Intent is processing: ${id}`);
                    break;
                default:
                    // Handle any unexpected status
                    rollbar.warning(`Unexpected payment status: ${status}`, {
                        id
                    });
                    break;
            }
        }
    });
};
