import { useDispatch, useSelector } from "react-redux";
import { ModalBase } from "../ModalBase";
import { closeCurrentModal } from "store/modal/slice";
import { useCallback } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import HubSpotForm from "components/forms/HubSpotForm";
import { RootState } from "store/reducers";

interface LaunchCancelModalProps {}

export const LaunchCancelModal: React.FC<LaunchCancelModalProps> = ({}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { userInfo } = useSelector((state: RootState) => state.user);

    const onHubSpotSuccess = useCallback(() => {
        localStorage.setItem("launchCancelModalSubmitted", "true");
        dispatch(closeCurrentModal());
    }, [dispatch]);

    return (
        <div className={styles["launch-cancel-modal"]}>
            <ModalBase
                header={t("subscription-page:trying-to-cancel")}
                successButton={undefined}
                dismissButton={undefined}
                isOpen={true}
                onDismiss={() => dispatch(closeCurrentModal())}
            >
                <HubSpotForm
                    formId="05fc9a46-05dc-4a17-8c3b-c04f4be61399"
                    onFormSubmitted={() => onHubSpotSuccess()}
                    onFormReady={(form) => {
                        form.querySelector('input[name="email"]').value =
                            userInfo?.Email;
                        form.querySelector('input[name="user_id"]').value =
                            userInfo?.UserId;
                    }}
                />
            </ModalBase>
        </div>
    );
};
