import React, { HTMLInputTypeAttribute } from "react";
import { StyledCheckboxOrRadio } from "../checkbox/StyledCheckboxOrRadio";
import styles from "./index.module.scss";
import { Select } from "../select/Select";

export interface CheckboxInputProps {
    checkBoxId?: string;
    inputId: string;
    checked?: boolean;
    showCheckbox?: boolean;
    onCheckboxChange?: () => void;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDropdownChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    inputType: HTMLInputTypeAttribute;
    inputName: string;
    showCurrencyIndicator?: boolean;
    value: string | number;
    min?: string | number;
    checkboxLabel?: string;
    placeholder?: string;
    dropdownId?: string;
    dropdownValue?: string;
    dropdownOptions?: string[];
    error?: string;
    disabled?: boolean;
}

export const CheckboxInput = ({
    checkBoxId,
    inputId,
    checked = false,
    showCheckbox = true,
    onCheckboxChange,
    onInputChange,
    onDropdownChange,
    inputType,
    showCurrencyIndicator = true,
    value,
    inputName,
    min,
    placeholder,
    dropdownId,
    dropdownValue,
    dropdownOptions,
    error,
    disabled
}: CheckboxInputProps) => {
    return (
        <div
            className={`${styles["field-container"]} ${
                disabled ? styles["disabled"] : ""
            }`}
        >
            {showCheckbox ? (
                <StyledCheckboxOrRadio
                    type="checkbox"
                    id={checkBoxId}
                    checked={checked}
                    onChange={onCheckboxChange}
                />
            ) : (
                !showCheckbox && <div className={styles["gap-container"]}></div>
            )}
            <div className={styles["input-column"]}>
                <div className={styles["label-row"]}>
                    <label htmlFor={checkBoxId}>{inputName}</label>
                </div>
                <div className={styles["price-row"]}>
                    {inputType === "number" && showCurrencyIndicator && (
                        <span className={styles["price-currency"]}>US$</span>
                    )}
                    <input
                        id={inputId}
                        name={inputName}
                        type={inputType}
                        min={min}
                        className={`form-control ${styles["checkbox-input"]} ${
                            dropdownOptions ? styles["input-with-dropdown"] : ""
                        }`}
                        value={value}
                        placeholder={placeholder}
                        onChange={onInputChange}
                        disabled={!checked && showCheckbox}
                        step="1.00"
                    />

                    {dropdownOptions && (
                        <Select
                            id={dropdownId}
                            className={`form-control ${styles["input-dropdown"]}`}
                            onChange={onDropdownChange}
                            selected={dropdownValue}
                            disabled={!checked}
                            options={dropdownOptions}
                            parentClassName="compact"
                            containerClassName="dropdown-group"
                        />
                    )}
                </div>

                {error && <div className={styles["error"]}>{error}</div>}
            </div>
        </div>
    );
};
