import { StripePrice } from "@switcherstudio/switcher-api-client";
import { PlanDetails, PlanDetailsProps } from "../../PlanDetails";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGenericMultiSelect } from "components/generic-multiselect/useGenericMultiSelect";
import { ComponentItem } from "components/generic-multiselect/types";
import { Spinner } from "components/spinners/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { userHasClaim } from "helpers/userHelper";
import { useCancelSubscription } from "hooks/useCancelSubscription";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { AttentionModal } from "components/modal/AttentionModal";
import { displayAmount } from "helpers/stripe";
import { useHasPlanRole } from "hooks/useHasPlanRole";

export interface SelectPlanStepProps {
    prices: StripePrice[];
    loading: boolean;
    currentPlan: string;
    selectPlan: (plan: StripePrice) => void;
    onHandleSubmit: (func) => void;
    onCancel: () => Promise<void>;
}

export const SelectPlanStep = ({
    prices,
    loading,
    currentPlan,
    selectPlan,
    onHandleSubmit,
    onCancel
}: SelectPlanStepProps) => {
    const { t } = useTranslation();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const dispatch = useDispatch();
    const planId = useMemo(() => userInfo?.PlanId, [userInfo?.PlanId]);
    const isGrowUser = useHasPlanRole("Grow");
    const [attentionModalIsOpen, setAttentionModalIsOpen] = useState(false);

    const { data: customer, loading: customerLoading } = useSwitcherClient(
        (client) => client.stripe_GetCustomer,
        {
            requestImmediately: true
        }
    );

    const subscriptionId = useMemo(
        () =>
            customer?.StripeSubscriptions?.[0]?.SilverSunnStripeSubscriptionId,
        [customer]
    );

    const subscriptionCost = useMemo(
        () => customer?.StripeSubscriptions?.[0]?.Amount ?? 0,
        [customer]
    );

    const subCancelledOrNonexistent = useMemo(
        () =>
            customer?.StripeSubscriptions?.[0]?.CanceledAt ||
            customer?.StripeSubscriptions?.[0]?.CancelAtPeriodEnd ||
            customer?.StripeSubscriptions?.length === 0,
        [customer]
    );

    /** Filters all public prices and returns the cheapest monthly one.
     * Used to check if a user is on a legacy plan and aid in retention. */
    const priceOfLowestCostAvailablePlan = useMemo(() => {
        const lowestCostMonthlyPlan = prices?.filter(
            (p) => p?.RecurringInterval === "month"
        );
        return lowestCostMonthlyPlan?.filter((p) => Math.min(p?.Amount))[0]
            ?.Amount;
    }, [prices]);

    const formattedLowAvailableCost = displayAmount(
        priceOfLowestCostAvailablePlan,
        {
            signed: true,
            compact: true,
            roundUp: false
        }
    );

    const { initiateProfitwellRetainAttempt } = useCancelSubscription(
        subscriptionId,
        planId,
        onCancel
    );

    /** Maths to calculate the savings of purchasing an annual sub (instead of monthly) */
    const savings = useMemo(() => {
        /** Filter for the correct plans */
        const businessYearly = prices?.filter((price) =>
            price?.Name?.includes("Business Yearly")
        )?.[0]?.Amount;
        const businessMonthly = prices?.filter((price) =>
            price?.Name?.includes("Business Monthly")
        )?.[0]?.Amount;
        const studioYearly = prices?.filter((price) =>
            price?.Name?.includes("Studio Yearly")
        )?.[0]?.Amount;
        const studioMonthly = prices?.filter((price) =>
            price?.Name?.includes("Studio Monthly")
        )?.[0]?.Amount;

        /** Multiply the cost of monthly sub by 12 to get annual cost of purchasing months.
         * Divide cost of purchasing monthly sub for a year by cost of actual annual sub.
         * Subtract from 1 to get the percentage saved.
         * Multiply by 100 to convert to percentage from decimal.
         */
        const businessMonthlyTimes12 = businessMonthly * 12;
        const businessPercent =
            (1 - businessYearly / businessMonthlyTimes12) * 100;

        const studioMonthlyTimes12 = studioMonthly * 12;
        const studioPercent = (1 - studioYearly / studioMonthlyTimes12) * 100;

        /** Return the values in an array as objects that will be accessed in the PlanDetails component */
        return [
            { name: "Business", percent: businessPercent },
            { name: "Studio", percent: studioPercent }
        ];
    }, [prices]);

    /**
     * If user account created after 9/24/24, no 7 Day Pass visible.
     * If user account created before 9/24/24, 7 Day Pass is
     * available for purchase only if they are not currently subscribed to a plan.
     * Otherwise, it's visible and  disabled.
     */
    const sevenDayPassState = useMemo<
        "active" | "inactive" | "unavailable"
    >(() => {
        if (userHasClaim("billing.onetimepass")) {
            if (userInfo?.ActiveUntil === null || !userInfo?.IsRecurring) {
                return "active";
            }
            return "inactive";
        }
        return "unavailable";
    }, [userInfo]);

    const sortPrices = useCallback((a: StripePrice, b: StripePrice) => {
        if (a?.Name?.includes("Launch")) {
            return -1;
        }
        if (a?.Name?.includes("Studio") && b.Name?.includes("Business")) {
            return -1;
        }
        if (a?.Name?.includes("Monthly") && b.Name?.includes("Yearly")) {
            return -1;
        }
        if (a?.Name?.includes("Pass") || b.Name?.includes("Pass")) {
            return -1;
        }
        return 1;
    }, []);

    const filterSevenDayPass = useMemo(() => {
        const sortedPrices = [...(prices ?? [])]?.sort(sortPrices);
        switch (sevenDayPassState) {
            case "active":
                return sortedPrices;
            case "inactive":
            case "unavailable":
                return sortedPrices?.filter(
                    (p) => !p?.Name?.includes("7-Day Pass")
                );
            default:
                return sortedPrices;
        }
    }, [prices, sevenDayPassState, sortPrices]);

    const planDetailsComponentItems: ComponentItem<StripePrice>[] =
        useMemo(() => {
            if (!prices?.length) return [];

            return filterSevenDayPass.map((price: StripePrice, key) => {
                const sevenDayPassDisabled =
                    price?.Name?.includes("Pass") &&
                    sevenDayPassState === "inactive";

                return {
                    id: price?.Id,
                    component: (
                        <PlanDetails
                            item={price}
                            key={key}
                            percentSavings={savings.filter((s) =>
                                price?.Name?.includes(s?.name)
                            )}
                            disabled={sevenDayPassDisabled}
                        />
                    ),
                    baseObject: price
                };
            });
        }, [prices, savings, filterSevenDayPass, sevenDayPassState]);

    const memoizedPlan = useMemo(
        () => (currentPlan != "" ? [currentPlan] : []),
        [currentPlan]
    );

    const { GenericMultiSelectComponent, handleSubmit, selectedIdSet } =
        useGenericMultiSelect<StripePrice, PlanDetailsProps>({
            items: planDetailsComponentItems,
            onSubmit: ([id]) => {
                if (selectPlan) {
                    const selectedPlan = selectedIdSet.has(id);
                    if (selectedPlan) {
                        const selected = prices?.filter(
                            (p) => p?.Id === id
                        )?.[0];
                        selectPlan(selected);
                    }
                }
            },
            previouslySelectedIds: memoizedPlan,
            allowNoSelection: false,
            loading
        });

    /** handler to get function from here to parent */
    useEffect(() => {
        if (selectedIdSet.size > 0) {
            onHandleSubmit(() => {
                handleSubmit();
            });
        }
    }, [onHandleSubmit, currentPlan, prices, handleSubmit, selectedIdSet]);

    const close = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.None,
                type: Modals.None
            })
        );
    }, [dispatch]);

    const deleteSubAndCloseModal = useCallback(async () => {
        initiateProfitwellRetainAttempt(subscriptionId);
        close();
    }, [initiateProfitwellRetainAttempt, close, subscriptionId]);

    const openAttentionModalOrProfitwellSalvage = useCallback(async () => {
        const userIsOnLegacyStudioPlan =
            !isGrowUser && subscriptionCost < priceOfLowestCostAvailablePlan;
        if (userIsOnLegacyStudioPlan) {
            setAttentionModalIsOpen(true);
        } else {
            deleteSubAndCloseModal();
        }
    }, [
        priceOfLowestCostAvailablePlan,
        subscriptionCost,
        deleteSubAndCloseModal,
        isGrowUser
    ]);

    const isLoading: boolean = useMemo(
        () => loading || customerLoading,
        [loading, customerLoading]
    );

    return (
        <>
            {isLoading ? (
                <div className={styles["spinner-container"]}>
                    <Spinner />
                </div>
            ) : (
                !!filterSevenDayPass && (
                    <div
                        className={`${styles["content"]} ${styles["disabled"]}`}
                    >
                        {GenericMultiSelectComponent}
                        {sevenDayPassState === "inactive" && (
                            <>
                                <hr />
                                <div className={styles["pass-disabled"]}>
                                    <PlanDetails
                                        item={prices?.find((p) =>
                                            p?.Name?.includes("7-Day Pass")
                                        )}
                                        disabled={true}
                                    />
                                </div>
                            </>
                        )}
                        {!subCancelledOrNonexistent && (
                            <>
                                <hr />
                                <button
                                    className={styles["cancel-plan"]}
                                    onClick={
                                        openAttentionModalOrProfitwellSalvage
                                    }
                                >
                                    <h6>{t(`subscription:cancel-plan`)}</h6>
                                </button>
                            </>
                        )}
                        <AttentionModal
                            isOpen={attentionModalIsOpen}
                            setIsOpen={setAttentionModalIsOpen}
                            continueText={t("subscription:cancel-plan")}
                            handleContinue={() => {
                                deleteSubAndCloseModal();
                            }}
                            handleCancel={() => setAttentionModalIsOpen(false)}
                        >
                            {t(
                                "subscription:cancel-legacy-studio-sub-warning",
                                {
                                    formattedLowAvailableCost
                                }
                            )}
                        </AttentionModal>
                    </div>
                )
            )}
        </>
    );
};
