import {
    Broadcast,
    BroadcastResponse,
    BroadcastStatus
} from "@switcherstudio/switcher-api-client";

export const mapBroadcastResponseToBroadcast = (
    response: BroadcastResponse
): Broadcast => {
    const mappedBroadcast: Broadcast = {
        Id: response?.Details?.Id,
        Title: response?.Details?.Title,
        Description: response?.Details?.Description,
        CreatedAt: response?.Details?.CreatedAt,
        CreatedBy: response?.Details?.CreatedBy,
        UpdatedAt: response?.Details?.UpdatedAt,
        StartsAt: response?.Details?.StartsAt,
        ActiveAt: response?.Details?.ActiveAt,
        EndedAt: response?.Details?.EndedAt,
        ProjectId: response?.Details?.ProjectId,
        InputId: response?.Details?.InputId,
        VideoId: response?.Details?.VideoId,
        IsProtected: response?.Details?.IsProtected,
        InputEffectiveDeletionDate:
            response?.Details?.InputEffectiveDeletionDate,
        BroadcastStatus: response?.Details
            ?.BroadcastStatus as any as BroadcastStatus,
        EnableRecording: response?.Details?.EnableRecording,
        EnableSwitcherPlayer: response?.Details?.EnableSwitcherPlayer,
        EnableLiveShopping: response?.Details?.EnableLiveShopping,
        ThumbnailAssetId: response?.Details?.ThumbnailAssetId,
        ThumbnailAsset: {
            SignedUrl: response?.Thumbnail?.Url,
            Id: response?.Details?.ThumbnailAssetId
        },
        Duration: response?.Details?.Duration,
        IsDirectToProvider: response?.Details?.IsDirectToProvider,
        ShareLink: response?.Details?.ShareLink,
        EmbedCode: response?.Details?.EmbedCode,
        ShowInCatalog: response?.Details?.ShowInCatalog,
        BroadcastWebLinks: response?.WebLinks?.map((link) => ({
            Link: link?.Link,
            Title: link?.Title,
            Sequence: link?.Sequence,
            VideoPlayerId: link?.VideoPlayerId,
            BroadcastId: link?.BroadcastId,
            PurchaseRequired: link?.PurchaseRequired,
            Id: link?.Id,
            CreatedAt: link?.CreatedAt,
            UpdatedAt: link?.UpdatedAt
        })),
        Categories: response?.Categories?.map((category) => ({
            Id: category?.Category?.Id,
            Name: category?.Category?.Name,
            ProjectId: category?.Category?.ProjectId,
            CategoryType: category?.Category?.CategoryType,
            Sequence: category?.Category?.Sequence,
            InsertedAt: category?.Category?.InsertedAt,
            CreatedAt: category?.Category?.CreatedAt,
            UpdatedAt: category?.Category?.UpdatedAt
        })),
        SwitcherStreamSettings: []
    };

    return mappedBroadcast;
};
