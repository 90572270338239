import React from "react";
import { useTranslation } from "react-i18next";
import { VideoDetailsPage } from ".";
import { usePageHeader } from "hooks/usePageHeader";
import { useParams } from "hooks/useParams";
import { useSwitcherClient } from "hooks/useSwitcherClient";

/** this page is the sister page of VideoLibraryVideoDetailsPage */
export const CollectionVideoDetailsPage: React.FC = () => {
    const { t } = useTranslation();
    const { playlistBroadcastId, videoPlayerId } = useParams();

    const {
        data: broadcast,
        dispatchApiRequest: refetchBroadcast,
        loading
    } = useSwitcherClient(
        (client) => client.videoPlayerPlaylistBroadcast_GetById,
        {
            args: [playlistBroadcastId, false],
            requestImmediately: true
        }
    );

    usePageHeader({
        title: t("page-titles:video-details"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("customization-page:collection-settings"),
            t("page-titles:video-details")
        ]
    });

    return (
        <VideoDetailsPage
            broadcast={broadcast?.Broadcast}
            video={broadcast?.Broadcast?.Videos?.result?.[0]}
            refetchBroadcast={refetchBroadcast}
            playlistBroadcast={broadcast}
            location="collection"
            broadcastLoading={loading}
            collectionId={videoPlayerId}
        />
    );
};
