import { DownloadStep } from "./DownloadStep";
import { CreateAccountStep } from "./CreateAccountStep";
import { SurveyStep } from "./SurveyStep";
import { LaunchSurveyStep } from "./LaunchSurveyStep";

const skipDownloadStep = import.meta.env.VITE_SKIP_DOWNLOAD_STEP === "true";

const NoSubscribeFlow = ({
    couponHook,
    stepsHook,
    clientId,
    redirectStatus,
    tag,
    defaultToSubscribeFlow,
    isGrowUser
}) => {
    const { resellerInventoryItem, handleCouponCode, removeCoupon } =
        couponHook;

    const { step, nextStep } = stepsHook;

    switch (step) {
        case 1:
            return (
                <CreateAccountStep
                    nextStep={nextStep}
                    clientId={clientId}
                    redirectStatus={redirectStatus}
                    tag={tag}
                    defaultToSubscribeFlow={defaultToSubscribeFlow}
                    resellerInventoryItem={resellerInventoryItem}
                    handleCouponCode={handleCouponCode}
                    removeCoupon={removeCoupon}
                />
            );
        case 2:
        case 3:
            if (isGrowUser === undefined) return <></>;
            return isGrowUser ? (
                <LaunchSurveyStep />
            ) : (
                <SurveyStep nextStep={nextStep} />
            );
        case 4:
            if (skipDownloadStep) return <></>;
            return <DownloadStep />;
        default:
            return <></>;
    }
};

export default NoSubscribeFlow;
