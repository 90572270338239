import { StripeElement } from "components/stripe/StripeElement";
import { redirectTo } from "router/loaders";
import { ConfirmationWrapper } from "views/page-content/subscription/confirm-subscription/ConfirmationWrapper";
import { NewSubscriptionPage } from "views/page-content/subscription/NewSubscriptionPage";
import { NewSubscriptionPageV2 } from "views/page-content/SubscriptionPage";
import { NewPaymentMethodPage } from "views/page-content/subscription/payment-method/NewPaymentMethodPage";
import { RemovePaymentMethodPage } from "views/page-content/subscription/payment-method/remove/RemovePaymentMethodPage";
import { PrimaryPaymentMethodPage } from "views/page-content/subscription/payment-method/set-primary/PrimaryPaymentMethodPage";
import { PaymentSuccessPage } from "views/page-content/subscription/payment-success/PaymentSuccessPage";
import { RenewSubscriptionPage } from "views/page-content/subscription/renew-subscription/RenewSubscriptionPage";
import { SubscriptionChangeSuccessPage } from "views/page-content/subscription/subscription-change-success/SubscriptionChangeSuccessPage";

const useNewBillingPage = import.meta.env.VITE_USE_NEW_BILLING_PAGE === "true";

export const SubscriptionRoutes = [
    {
        path: "billing",
        loader: redirectTo("/subscription")
    },
    {
        path: "subscription",
        element: useNewBillingPage ? (
            <StripeElement>
                <NewSubscriptionPageV2 />
            </StripeElement>
        ) : (
            <NewSubscriptionPage />
        )
    },
    {
        path: "subscription/subscribe",
        loader: redirectTo(
            useNewBillingPage
                ? "/subscription#openModal"
                : "/subscription/subscribe"
        )
    },
    {
        path: "subscription/subscribe/:priceId",
        element: <ConfirmationWrapper />
    },
    {
        path: "subscription/renew",
        element: <RenewSubscriptionPage />
    },
    {
        path: "subscription/success",
        element: <PaymentSuccessPage />
    },
    {
        path: "subscription/change-success",
        element: <SubscriptionChangeSuccessPage />
    },
    {
        path: "subscription/payment-method",
        element: <StripeElement />,
        children: [
            {
                path: "",
                element: <NewPaymentMethodPage />
            }
        ]
    },
    {
        path: "subscription/payment-method/:id/primary",
        element: <PrimaryPaymentMethodPage />
    },
    {
        path: "subscription/payment-method/:id/remove",
        element: <RemovePaymentMethodPage />
    }
];
