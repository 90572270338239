import React, { useCallback, useEffect, useState } from "react";
import mpstyles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { GatedContentPassFormProps, PricingModalTypes } from ".";
import { Errors } from "hooks/useForm";
import { useDebounce } from "hooks/useDebounce";
import { CheckboxInput } from "components/inputs/checkbox-input";

export const OneTimePassField = ({
    values: {
        oneTimePrice,
        oneTimeDurationSelected,
        oneTimeDuration,
        oneTimeDurationUnits
    },
    errors,
    onChange,
    type
}: {
    values: GatedContentPassFormProps;
    errors: Errors;
    onChange: (key: string, val: any) => void;
    type: PricingModalTypes;
}) => {
    const timedAccessPassesEnabled =
        import.meta.env.VITE_ENABLE_OTP_EXPANSION === "true";

    const { t } = useTranslation();

    const [initialOneTimeDuration] = useState<string>(oneTimeDuration);

    const [oneTimePriceDebounced, setOneTimePriceDebounced] =
        useState<string>(oneTimePrice);

    const [oneTimeDurationDebounced, setOneTimeDurationDebounced] =
        useState<string>(oneTimeDuration);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e?.currentTarget?.value;

        setOneTimePriceDebounced(newValue);
    };

    const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e?.currentTarget?.value;
        setOneTimeDurationDebounced(newValue);
    };

    const handleDurationUnitsChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const newValue = e?.currentTarget?.value;

        handleParentChange("oneTimeDurationUnits", newValue);
    };

    useDebounce(oneTimePriceDebounced, 500, {
        onUpdate: (price) => handleParentChange("oneTimePrice", price)
    });

    useDebounce(oneTimeDurationDebounced, 500, {
        onUpdate: (duration) => handleParentChange("oneTimeDuration", duration)
    });

    const handleParentChange = (key: string, value: any) => {
        onChange(key, value);
    };

    const handleTimedAccessChange = useCallback(() => {
        const newIsTimedAccess = !oneTimeDurationSelected;

        handleParentChange("oneTimeDurationSelected", newIsTimedAccess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneTimeDurationSelected, oneTimeDurationUnits]);

    useEffect(() => {
        if (oneTimeDurationSelected && !oneTimeDurationUnits) {
            handleParentChange("oneTimeDurationUnits", "Hours");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneTimeDurationSelected, oneTimeDurationUnits]);

    return (
        <>
            <div className={`${mpstyles["pricing-form-expanded-content"]}`}>
                <div className={mpstyles["one-time-pass-price-container"]}>
                    <CheckboxInput
                        showCheckbox={false}
                        inputId="price"
                        inputName={t("gated-content-modal:one-time-price")}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE}
                        value={oneTimePriceDebounced}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        onInputChange={handleChange}
                        error={errors.oneTimePrice}
                        disabled={false}
                    />
                    {timedAccessPassesEnabled && (
                        <CheckboxInput
                            checkBoxId="timed-access-checkbox"
                            inputId="timed-access-input"
                            inputName={t("misc:timed-access-price")}
                            checked={oneTimeDurationSelected}
                            onCheckboxChange={handleTimedAccessChange}
                            onInputChange={handleDurationChange}
                            inputType="number"
                            showCurrencyIndicator={false}
                            min={1}
                            placeholder={t(
                                "gated-content-modal:duration-placeholder"
                            )}
                            value={oneTimeDuration}
                            error={errors.oneTimeDuration}
                            disabled={!oneTimeDurationSelected}
                            onDropdownChange={handleDurationUnitsChange}
                            dropdownId="timed-access-dropdown"
                            dropdownValue={oneTimeDurationUnits}
                            dropdownOptions={["Hours", "Days", "Weeks"]}
                        />
                    )}
                </div>
                {type === PricingModalTypes.Update &&
                    timedAccessPassesEnabled &&
                    oneTimeDurationSelected &&
                    !initialOneTimeDuration && (
                        <div
                            className={`${mpstyles["sub-error"]} ${mpstyles["pass-duration-warning"]}`}
                        >
                            {t("gated-content-modal:timed-access-pass-warning")}
                        </div>
                    )}
            </div>
        </>
    );
};
