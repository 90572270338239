import { redirectTo, routeLoader } from "router/loaders";
import { VideoLibraryPage } from "views/page-content/video-library";
import { VideoLibraryVideoDetailsPage } from "views/page-content/VideoDetailsPage/VideoLibraryVideoDetailsPage";

const videoLibraryRouteData = {
    badgeConfig: {
        claimCheck: false,
        claim: "videoplayer",
        label: "preview",
        class: "badge-preview"
    },
    upgradeConfig: {
        claimCheck: false,
        claim: "videoplayer",
        label: "upgrade-business"
    }
};

export const VideoLibraryRoutes = [
    {
        path: "videos",
        loader: redirectTo("/video-library")
    },
    {
        path: "video-library",
        element: <VideoLibraryPage />
    },
    {
        path: "video-library/video/:broadcastId",
        element: <VideoLibraryVideoDetailsPage />,
        loader: routeLoader({
            data: videoLibraryRouteData
        })
    }
];
