import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { NewSubscriptionGridV2 } from "./NewSubscriptionGridV2";
import { InvoiceTableV2 } from "./InvoiceTableV2";
import {
    SilverSunnStripeSubscription,
    StripePrice,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { displayDate } from "helpers/time";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import {
    mustBeSubscriptionOwner,
    useRedirectIfDisallowed
} from "hooks/useRedirectIfDisallowed";
import { StripeStatus } from "../platforms/types";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { NextPaymentOnV2 } from "./NextPaymentOnV2";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { ManagePlanModal } from "components/modal/ManagePlanModal";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { NewEntitlementGridV2 } from "./NewEntitlementGridV2";
import { removeQueryParams } from "helpers/url";
import { useGetStripePrice } from "hooks/useGetStripePrice";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import { SetupIntentElement } from "components/stripe/SetupIntentElement";
import { useCheckoutRedirect } from "hooks/useCheckoutRedirect";
import {
    refreshToken,
    getUserInfo,
    getUserOrgs,
    getDefaultUserOrg
} from "store/user/thunks";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import { client } from "api/client";

export interface SubscriptionWithPrice {
    subscription?: SilverSunnStripeSubscription;
    price?: StripePrice;
}

export const NewSubscriptionPageV2: React.FC = () => {
    // Extract couponCode and planId from the query parameters
    const { searchParams } = useMemo<URL>(
        () => new URL(window.location.href),
        []
    );
    const [refetchInvoicesCounter, setRefetchInvoicesCounter] =
        useState<number>(0);
    const [checkoutHandled, setCheckoutHandled] = useState<boolean>(false);
    const [couponCode, setCouponCode] = useState(
        searchParams.get("couponCode")
    );
    const planId = useMemo(() => {
        // Only return plan if redirect_status is not present meaning we are handling a checkout
        if (!searchParams.get("redirect_status")) {
            return searchParams.get("planId")?.toUpperCase();
        }
    }, [searchParams]);
    const hideSubscriptionPlans =
        searchParams.get("hideSubscriptions") === "true";
    const [hasOpenModalHash, setHasOpenModalHash] = useState<boolean>(
        window.location.hash.includes("#openModal")
    );
    const isGrowUser = useHasPlanRole("Grow");

    useRedirectIfDisallowed(mustBeSubscriptionOwner);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((state: RootState) => state.user);

    const [hasActiveSub, setHasActiveSub] = useState<boolean>(false);
    const [stripePrice, setStripePrice] = useState<StripePrice>();
    const [detailedSubscriptions, setDetailedSubscriptions] =
        useState<SubscriptionWithPrice[]>(undefined);
    const { claimedCoupon } = useGetLastClaimedCoupon({
        couponCode: couponCode
    });
    const [preselectedPrice, setPreselectedPrice] = useState<
        StripePrice | StripePriceAnonymous
    >();
    const anonymousPlan = useGetStripePrice(planId)?.plan;

    const {
        data: prices,
        loading: stripePricesLoading,
        dispatchApiRequest: getStripePrices
    } = useSwitcherClient((client) => client.userStripePrices_GetStripePrices, {
        args: [userInfo.UserId],
        requestImmediately: true
    });
    const [filteredPrices, setFilteredPrices] = useState<StripePrice[]>(prices);

    /** show "exit" modal only for launch plan users */
    useEffect(() => {
        const hasSubmitted = localStorage.getItem("launchCancelModalSubmitted");
        if (
            isGrowUser &&
            userInfo?.Status == StripeStatus.Trialing &&
            !hasSubmitted
        ) {
            dispatch(setActiveModal(Modals.LaunchCancelModal));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { dispatchApiRequest: setPrimaryPaymentMethod } = useSwitcherClient(
        (client) => client.userPaymentMethods_SetPrimaryPaymentMethod,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: deletePaymentMethod } = useSwitcherClient(
        (client) => client.userPaymentMethods_DeletePaymentMethod,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    useEffect(() => {
        if (!prices) return;

        let localPrices = [...prices];

        /** if user does not have the app claim, filter out Studio tier plans */
        if (isGrowUser) {
            localPrices = localPrices?.filter(
                (p) =>
                    !p?.Name.includes("Switcher Studio Monthly") &&
                    !p?.Name.includes("Studio Yearly")
            );
        }

        /** if user has been redirected here to add a NON subscription plan, filter out the subscription plans */
        if (hideSubscriptionPlans) {
            localPrices = localPrices?.filter((p) => !p?.IsRecurring);
        }

        /** otherwise, get and use all available prices! */
        setFilteredPrices(localPrices);
    }, [isGrowUser, prices, hideSubscriptionPlans]);

    /** if there is a planId in the querystring params, use it to preselect the plan
     * in the modal and advance the user to the checkout step */
    useEffect(() => {
        if (!!planId) {
            const planIsPublic = prices?.find((p) => p?.Id === planId);
            if (!!planIsPublic) {
                setPreselectedPrice(planIsPublic);
                return;
            }
            if (anonymousPlan) {
                setPreselectedPrice(anonymousPlan);
                return;
            }
        }
        setPreselectedPrice(undefined);
    }, [prices, anonymousPlan, planId]);

    /** if there is a plan/price associated with the coupon in the querystring,
     * preselect that plan in the modal and advance the user to the checkout step */
    useEffect(() => {
        const priceAssociatedWithCoupon =
            claimedCoupon?.ResellerInventoryItem?.ResellerInventory?.Price;
        if (couponCode && !!priceAssociatedWithCoupon) {
            setPreselectedPrice(priceAssociatedWithCoupon);
        }
    }, [claimedCoupon?.ResellerInventoryItem, couponCode]);

    const { data: customer, dispatchApiRequest: getCustomer } =
        useSwitcherClient((client) => client.stripe_GetCustomer, {
            requestImmediately: true,
            onError: (e) => {
                rollbar.error(
                    "Error getting user stripe customer subscription info",
                    e
                );
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:subscription-info-error"
                    })
                );
            },
            onSuccess: async (data) => {
                const isLapsedSub =
                    userInfo?.Roles?.includes("Lapsed") &&
                    userInfo?.Status !== StripeStatus.Incomplete;
                const isTrialSub = userInfo?.Roles?.includes("Trial");
                const isShopifyCustomer =
                    userInfo?.ActiveProduct?.discriminator?.includes(
                        "ShopifyProduct"
                    );

                if (
                    !data ||
                    ((isLapsedSub || isTrialSub) &&
                        data.StripeCreditCards?.length === 0 &&
                        !isShopifyCustomer)
                ) {
                    navigate("/subscription");
                } else {
                    data.StripeCreditCards?.sort((a, b) =>
                        a.Default === b.Default ? 0 : a.Default ? -1 : 1
                    );
                    const activeSub = data?.StripeSubscriptions.find(
                        (s) =>
                            s.Status === StripeStatus.Active ||
                            s.Status === StripeStatus.Trialing
                    );
                    setHasActiveSub(!!activeSub);

                    if (!!activeSub) {
                        getStripePrices([userInfo.UserId, activeSub.PlanId]);
                    }
                }
            }
        });

    const { data: entitlements, dispatchApiRequest: getEntitlements } =
        useSwitcherClient(
            (client) => client.userEntitlements_getActiveUserEntitlements,
            { requestImmediately: true, args: [userInfo.UserId] }
        );

    const isAppleSub = useMemo(() => {
        return (
            userInfo.ActiveProduct?.discriminator === "AppleProduct" &&
            userInfo.IsRecurring === true
        );
    }, [userInfo]);

    const licensedSubscription = useMemo(
        () =>
            customer?.StripeSubscriptions?.find(
                (s) => s.UsageType === "licensed"
            ),
        [customer]
    );

    const {
        /** This is the last subscription invoice Stripe tried to collect and failed. If this exists then the account is overdue */
        transformedData: lastAttemptedInvoice,
        dispatchApiRequest: refetchLastAttemptedInvoice
    } = useSwitcherClient(
        (client) => client.userSubscriptions_GetSubscription,
        {
            requestImmediately: true,
            args: [
                userInfo.UserId,
                licensedSubscription?.SilverSunnStripeSubscriptionId
            ],
            transformResponseData: async ({
                originalResponse: { LatestInvoiceId }
            }) => {
                const invoice = await client.stripeInvoices_GetInvoice(
                    LatestInvoiceId,
                    userInfo.UserId
                );

                if (
                    invoice.payment_intent_status !== null && // is draft invoice
                    invoice.payment_intent_status !== "succeeded" // has been paid
                ) {
                    return invoice;
                }
            }
        }
    );

    // combined subscriptions, products and price
    useEffect(() => {
        if (!!customer && !!prices) {
            const detailedSubs: SubscriptionWithPrice[] =
                customer.StripeSubscriptions.map((sub) => {
                    const matchingPrice = prices.find(
                        (p) => p?.Id === sub?.PlanId
                    );
                    setStripePrice(matchingPrice);
                    return {
                        subscription: sub,
                        price: matchingPrice
                    };
                });
            setDetailedSubscriptions(detailedSubs);
        }
    }, [customer, prices]);

    const displayCost = useCallback(
        (amount?: number, showPeriodText?: boolean) => {
            let periodText = "";
            if (!!stripePrice && hasActiveSub) {
                periodText =
                    stripePrice.RecurringInterval === "year"
                        ? t("subscription:per-year")
                        : t("subscription:per-month");
            }
            return `${((amount || 0) / 100).toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            })}${showPeriodText ? ` ${periodText}` : ""}`;
        },
        [stripePrice, hasActiveSub, t]
    );

    const _displayDate = (date?: string) => {
        return displayDate(date, i18n.language, "dddd, MMMM D, YYYY");
    };

    const refreshCustomer = useCallback(
        async (args?: { message?: string }) => {
            await dispatch(refreshToken());
            await dispatch(getUserInfo());

            await Promise.allSettled([
                dispatch(getUserOrgs()),
                dispatch(getDefaultUserOrg()),
                getCustomer(),
                getEntitlements(),
                refetchLastAttemptedInvoice()
            ]);

            // increment refetch counter to re-fetch data in the invoice table
            setRefetchInvoicesCounter((prev) => prev + 1);

            removeQueryParams();
            setPreselectedPrice(undefined);

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: args?.message ?? t("subscription:success")
                })
            );
        },
        [dispatch, getCustomer, getEntitlements, refetchLastAttemptedInvoice, t]
    );

    useCheckoutRedirect({
        onSuccess: async ({ message }) => {
            try {
                await refreshCustomer({ message });
            } catch (e) {
                rollbar.error("Error refreshing user info", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:user-info-load-error")
                    })
                );
            }
        },
        onFailure: () => {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("subscription-page:payment-error")
                })
            );
        }
    });

    const openManagePlanModal = useCallback(() => {
        dispatch(
            setActiveModal({
                id: "manage-plan",
                type: Modals.ManagePlanModal,
                component: (
                    <SetupIntentElement>
                        <ManagePlanModal
                            preselectedPrice={preselectedPrice}
                            prices={filteredPrices}
                            couponCode={couponCode}
                            stripePricesLoading={stripePricesLoading}
                            customer={customer}
                            licensedSubscription={licensedSubscription}
                            isPastDue={
                                !!lastAttemptedInvoice ||
                                licensedSubscription?.Status ===
                                    StripeStatus.PastDue ||
                                licensedSubscription?.Status ===
                                    StripeStatus.PastDue
                            }
                            lastAttemptedInvoice={lastAttemptedInvoice}
                            onCheckoutSuccess={async ({
                                selectedPaymentMethodId
                            }) => {
                                // Set payment as primary if not already
                                if (
                                    !customer?.StripeCreditCards.find(
                                        (method) =>
                                            method.SilverSunnStripeCreditCardId ===
                                            selectedPaymentMethodId
                                    )?.Default
                                ) {
                                    await setPrimaryPaymentMethod([
                                        userInfo.UserId,
                                        selectedPaymentMethodId
                                    ]);
                                }

                                refreshCustomer();
                            }}
                            onCheckoutError={async (
                                e,
                                { selectedPaymentMethodId, stripeError }
                            ) => {
                                const availablePaymentMethods =
                                    customer?.StripeCreditCards;
                                // If a new payment method is used, try to reset the user's default
                                // payment method and delete the new failed one.
                                if (
                                    selectedPaymentMethodId &&
                                    selectedPaymentMethodId !==
                                        "new-payment-method"
                                ) {
                                    try {
                                        if (availablePaymentMethods.length) {
                                            const originalDefault =
                                                availablePaymentMethods.find(
                                                    (c) =>
                                                        c.Default && !c.Expired
                                                );
                                            if (originalDefault) {
                                                await setPrimaryPaymentMethod([
                                                    userInfo.UserId,
                                                    originalDefault.SilverSunnStripeCreditCardId
                                                ]);
                                            }
                                        }

                                        if (
                                            selectedPaymentMethodId !==
                                            "new-payment-method"
                                        ) {
                                            await deletePaymentMethod([
                                                userInfo.UserId,
                                                selectedPaymentMethodId
                                            ]);
                                        }
                                    } catch {
                                        /** eat DeletePaymentMethod request errors */
                                    }
                                }

                                if (!!stripeError?.decline_code) {
                                    rollbar.warning(
                                        "Payment collection error with decline code",
                                        e
                                    );
                                } else {
                                    rollbar.error(
                                        "Payment collection error without decline code",
                                        e
                                    );
                                }

                                dispatch(
                                    addNotification({
                                        type: NotificationType.Danger,
                                        message: t(
                                            "subscription-page:payment-error"
                                        )
                                    })
                                );
                            }}
                            onCancel={async () => {
                                await refreshCustomer();
                                dispatch(
                                    addNotification({
                                        type: NotificationType.Success,
                                        message: t("subscription:success")
                                    })
                                );
                            }}
                        />
                    </SetupIntentElement>
                )
            })
        );
    }, [
        dispatch,
        preselectedPrice,
        filteredPrices,
        couponCode,
        stripePricesLoading,
        customer,
        licensedSubscription,
        lastAttemptedInvoice,
        refreshCustomer,
        setPrimaryPaymentMethod,
        userInfo.UserId,
        t,
        deletePaymentMethod
    ]);

    /** automatically open the modal if query params exist */
    useEffect(() => {
        /** wait for prices to load before showing modal */
        if (!!filteredPrices && !checkoutHandled && !!customer) {
            if (
                hasOpenModalHash ||
                hideSubscriptionPlans ||
                !!preselectedPrice ||
                !!couponCode
            ) {
                openManagePlanModal();
                /** prevent modal from reopening */
                setCheckoutHandled(true);
                setHasOpenModalHash(false);
                setCouponCode(undefined);
            }
        }
    }, [
        checkoutHandled,
        openManagePlanModal,
        hasOpenModalHash,
        filteredPrices,
        hideSubscriptionPlans,
        preselectedPrice,
        couponCode,
        customer
    ]);

    return (
        <div className="row">
            <div className={`${styles["subscription-page-wrapper"]} col`}>
                {isAppleSub ? (
                    <div className={styles["current-plan-row"]}>
                        <p>
                            {t("subscription-page:apple-subscription-message")}
                        </p>
                    </div>
                ) : (
                    <>
                        <div className={styles["current-plan-row"]}>
                            {!hasActiveSub && entitlements?.length ? (
                                <NewEntitlementGridV2
                                    entitlements={entitlements}
                                    openManagePlanModal={openManagePlanModal}
                                />
                            ) : (
                                <NewSubscriptionGridV2
                                    subscriptions={detailedSubscriptions}
                                    isPastDue={!!lastAttemptedInvoice}
                                    displayCostFn={displayCost}
                                    openManagePlanModal={openManagePlanModal}
                                />
                            )}
                            <NextPaymentOnV2
                                customer={customer}
                                refetchCustomer={getCustomer}
                                isPastDue={!!lastAttemptedInvoice}
                                activeEntitlement={
                                    !hasActiveSub && entitlements?.[0]
                                }
                                activePlan={detailedSubscriptions?.[0]}
                            />
                        </div>
                        <InvoiceTableV2
                            displayCostFn={displayCost}
                            displayDateFn={_displayDate}
                            refetchCounter={refetchInvoicesCounter}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
