/** Modals that don't need any props to open */
export enum Modals {
    None = "none",
    TriggeredVideoUploadModal = "triggered-video-upload-modal",
    EditBroadcastModal = "edit-broadcast-modal",
    AddVideosModal = "add-videos-modal",
    PlayerSelectModal = "player-select-modal",
    VideoPlaybackModal = "video-playback-modal",
    AttentionModal = "attention-modal",
    UploadOrChooseVideoModal = "upload-or-choose-modal",
    ContactSalesModal = "contact-sales-modal",
    Booking = "booking",
    ManagePlanModal = "manage-plan-modal",
    LaunchCancelModal = "launch-cancel-modal"
}

export interface ModalPayload {
    /**
     * unique identifier for modals that can be duplicated (like the video upload session modal)
     * for modals that are not duplicated, id and type will be the same
     */
    id: Modals | string;
    type: Modals;
    component?: JSX.Element;
}
export interface ModalsState {
    /** Either the enum value for a modal to open or the modal itself */
    activeModal: ModalPayload;
    previousModal: ModalPayload;
    minimizedModals: ModalPayload[];
}
