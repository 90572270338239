import { Button } from "../Button";
import styles from "./index.module.scss";
import { useMemo } from "react";

export interface DisplaySelectedButtonProps<T> {
    /** the array of items */
    selected: T[];
    /** a callback that calls on button click */
    onClick: () => void;
    /** text on the button when no items exist in the array */
    buttonText: string;
    /** the name item to appear on the button */
    itemText: string;
    /** a label for the button to be rendered in a row */
    label?: string;
    /** overrides the suffix for plurality if array is greater than 1. Defaults to "s"  */
    plural?: string;
    /** style variants */
    variant?: "row" | "block";
}

/**
 * A button that will tell the count of a given array if there are items in the array. Typically, this will open a modal that will  allow for selecting multiples of a given item, like players or passes.
 */
export const DisplaySelectedButton = <T,>({
    selected,
    onClick,
    buttonText,
    itemText,
    label,
    plural = "s",
    variant = "row"
}: DisplaySelectedButtonProps<T>) => {
    const hasSelected = useMemo<boolean>(
        () => selected?.length > 0,
        [selected]
    );

    return (
        <span className={styles[variant]}>
            {label && <h6>{label}</h6>}
            <Button onClick={onClick} isActive={hasSelected} type="badge">
                {hasSelected
                    ? `${selected.length} ${itemText}${
                          selected.length > 1 ? plural : ""
                      }`
                    : buttonText}
            </Button>
        </span>
    );
};
